import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Spin } from 'antd'
import HeadHtml from '@src/components/layout/HeadHtml'
import { checkAuth } from '@src/libs/localStorage'

function Login() {
  const navigate = useNavigate()
  const token: string = checkAuth()

  useEffect(() => {
    if (token) navigate('/')
  }, [navigate, token])

  return (
    <div className="login-container">
      <HeadHtml title="Login" />
      <Row align="middle" justify="center" className="login-boxer" gutter={[24, 60]} style={{ width: '100vw', height: '100vh' }}>
        <Spin tip="Loading..." />
      </Row>
    </div>
  )
}

export default Login
