import { PERMISSIONS } from '@src/configs/permissions'
import { RoleItemType } from '@src/modules/role'

export const usePermission = () => {
  const roles: RoleItemType[] = []
  const newPermissions: string[] = []
  if (Array.isArray(roles) && roles?.length > 0) {
    roles.forEach((role: { code: string; permissions: string[] }) => {
      if (Array.isArray(role?.permissions) && role?.permissions?.length > 0) {
        role?.permissions.forEach((permission: string) => {
          if (permission) newPermissions.push(permission)
        })
      }
    })
  }
  return newPermissions?.length > 0 ? [...newPermissions, PERMISSIONS.GENERAL] : [PERMISSIONS.GENERAL]
}
