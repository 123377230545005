import '@progress/kendo-theme-default/dist/all.css'
import './styles/styles.less'

import LoadingApp from '@components/widgets/LoaderApp'
import { queryClient } from '@queries/index'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  // BrowserRouter,
  HashRouter,
} from 'react-router-dom'

import RouteApp from './route'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <LoadingApp loaded={false}>
          <HashRouter>
            <RouteApp />
          </HashRouter>
        </LoadingApp>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
