import { memo } from 'react'
import { Spin } from 'antd'

function LoadingApp({ loaded, children }: { loaded: boolean; children: JSX.Element }) {
  if (loaded)
    return (
      <div className="loading-data">
        <Spin tip="Đang tải dữ liệu" />
      </div>
    )
  return children
}

export default memo(LoadingApp)
