import NotFound from '@components/widgets/404'
import Login from '@pages/login'
import LayoutApp from '@src/components/layout'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'
import routeConfig, { RouteConfigItemType } from './routeConfig'

function RouteApp() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <>
        {routeConfig.map(({ path, Element, key }: RouteConfigItemType, index: number) => (
          <Route
            path={path}
            key={index}
            element={
              <ProtectedRoute keyName={key}>
                <LayoutApp>
                  <Element />
                </LayoutApp>
              </ProtectedRoute>
            }
          />
        ))}
      </>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate to="/kiem-tra/khoi-dong" replace />} />
      <Route path="/kiem-tra" element={<Navigate to="/kiem-tra/khoi-dong" replace />} />
    </Routes>
  )
}

export default RouteApp
