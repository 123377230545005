import { BASE_URL_WP } from '@src/configs/common'
import { Result, Button } from 'antd'

export default function () {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Xin lỗi, bạn không được phép truy cập trang này."
      extra={
        <Button type="primary" style={{ fontSize: 14 }}>
          <a href={BASE_URL_WP}>Quay lại trang chủ</a>
        </Button>
      }
    />
  )
}
