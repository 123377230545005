import { BASE_URL_WP } from '@src/configs/common'
import { Result, Button } from 'antd'

export default function ({ subTitle = 'Xin lỗi, trang bạn đã truy cập không tồn tại.' }: { subTitle?: string }) {
  return (
    <Result
      status="404"
      title="404"
      subTitle={subTitle}
      extra={
        <Button type="primary" style={{ fontSize: 14 }}>
          <a href={BASE_URL_WP}>Quay lại trang chủ</a>
        </Button>
      }
    />
  )
}
