import { BASE_URL } from '@src/configs/api'
import { ResApi } from '@src/configs/resApi.interface'
import { checkAuth } from '@src/libs/localStorage'
import { TProfile } from '@src/modules/user'
import { GET_PROFILE_2 } from '@src/queries/keys'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetProfile = (token: string) => {
  const accessToken = checkAuth()
  const handleGetData = async (t: string) => {
    const res = await axios.get(`${BASE_URL}/user/profile`, {
      headers: { Authorization: `Bearer ${t || accessToken}` },
    })
    return res?.data
  }
  return useQuery<ResApi<TProfile>>([GET_PROFILE_2, token], () => handleGetData(token), {
    enabled: !!token,
    retry: 1,
  })
}
