import React, { ReactNode } from 'react'
import { Layout } from 'antd'

import Footer from './Footer'
import Header from './Header'

interface LayoutAppProps {
  children: ReactNode
}

const LayoutApp: React.FC<LayoutAppProps> = ({ children }) => (
  <Layout style={{ minHeight: '100vh', background: '#ffffff' }} hasSider>
    <Layout className="site-layout">
      <Header />
      <main>{children}</main>
      <Footer />
    </Layout>
  </Layout>
)

export default LayoutApp
