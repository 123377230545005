import { BASE_URL_WP } from './common'

export const MAIN_LINK = {
  HOME: BASE_URL_WP,
  MISSION_VISION: `${BASE_URL_WP}su-menh-va-tam-nhin`,
  EVALUATION_MODEL: `${BASE_URL_WP}mo-hinh-danh-gia`,
  ADVISORY_TEAM: `${BASE_URL_WP}doi-ngu-co-van`,
  CAREER_SOLUTIONS: `${BASE_URL_WP}giai-phap-huong-nghiep`,
  COURSES: `${BASE_URL_WP}khoa-hoc`,
  DATA_SOURCE: `${BASE_URL_WP}thong-tin-thpt`,
  USAGE_POLICY: `${BASE_URL_WP}chinh-sach-giai-quyet-khieu-nai`,
  PRIVACY_POLICY: `${BASE_URL_WP}chinh-sach-bao-mat-thong-tin`,
  PAYMENT_POLICY: `${BASE_URL_WP}chinh-sach-thanh-toan`,
  USER_GUIDE_VIDEO: 'https://www.youtube.com/watch?v=Q90arlbAFxk',
  FAQ: `${BASE_URL_WP}cau-hoi-thuong-gap`,
  CONTACT: `${BASE_URL_WP}lien-he`,
  NEWS: `${BASE_URL_WP}tin-tuc`,
  EVENT: `${BASE_URL_WP}su-kien`,
  PODCAST: `${BASE_URL_WP}podcast`,
  FACEBOOK: 'https://www.facebook.com/askforpac/',
  YOUTUBE: 'https://www.youtube.com/c/PACGroupAcademicCounselingCareerAdvising',
  INSTAGRAM: 'https://www.instagram.com/pacgroupvietnam/',
  SPOTIFY: 'https://open.spotify.com/show/0mkRGGzZRwNif8ujpUSAhj',
  MAIL: 'info@pacgroup.org',
  PHONE_NUMBER: '096 601 3663',
  PROFILE: `${BASE_URL_WP}user-ho-so-thong-tin`,
}
