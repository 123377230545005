import React from 'react'
import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'

export type RouteConfigItemType = {
  path: string
  Element: React.FC
  key: PERMISSIONS
}

const Dashboard = lazyLoading(() => import('@pages/dashboard'))
const Test = lazyLoading(() => import('@pages/test'))
const Result = lazyLoading(() => import('@pages/result'))
// const ListRole = lazyLoading(() => import('@pages/roles/ListRole'))

const routeConfig: RouteConfigItemType[] = [
  {
    path: '/kiem-tra/:type',
    Element: Dashboard,
    key: PERMISSIONS.GENERAL,
  },
  {
    path: 'kiem-tra/:type/bai-kiem-tra-tinh-cach/',
    Element: Test,
    key: PERMISSIONS.GENERAL,
  },
  {
    path: 'kiem-tra/ket-qua/:id',
    Element: Result,
    key: PERMISSIONS.GENERAL,
  },
]

export default routeConfig
