// import { useEffect } from 'react'
import {
  Outlet,
  // useNavigate
} from 'react-router-dom'
import { PERMISSIONS } from '@src/configs/permissions'
import { usePermission } from '@src/hooks/usePermissions'
// import { checkAuth } from '@src/libs/localStorage'
import Forbidden from '@components/widgets/403'

function ProtectedRoute({ keyName, children }: { keyName: PERMISSIONS; children?: JSX.Element }) {
  // const token: string = checkAuth()
  const permissions = usePermission()

  // const navigate = useNavigate()
  // useEffect(() => {
  //   if (!token) navigate('/login')
  // }, [navigate, token])

  if (!permissions.includes(keyName)) return <Forbidden />

  return children || <Outlet />
}
export default ProtectedRoute
