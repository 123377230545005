export const BASE_URL_WP = 'https://unlockyourcareer.vn/'

export const LANGUAGE = ['en', 'vi']
export const LANGUAGE_DEFAULT = 'en'

export const ADMINISTRATOR = 'ADMINISTRATOR'
export const ENTERPRISE = 'ENTERPRISE'
export const AGENCY = 'AGENCY'
export const GUEST = 'GUEST'

export const ROLES = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  ENTERPRISE: 'ENTERPRISE',
  AGENCY: 'AGENCY',
  GUEST: 'GUEST',
}

export const ROLES_COLORS: {
  [key: string]: string
} = {
  ADMINISTRATOR: 'volcano',
  ENTERPRISE: 'blue',
  AGENCY: 'purple',
  GUEST: 'geekblue',
}
