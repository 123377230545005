import { useMutation, useQuery, useQueryClient } from 'react-query'
import logger from '@libs/logger'
import { getProfile, refreshToken, signOut, signIn, signInByTokenWp } from '@queries/api'
import { GET_PROFILE_2, USER_PROFILE } from '@queries/keys'
import { ResApi, ResApiErr } from '@configs/resApi.interface'
import { checkAuth, clearStoredAuth, getStoredAuth, setStoredAuth } from '@libs/localStorage'
import { Auth } from '@src/modules/auth'
import { notification } from 'antd'
import { TProfile } from '@src/modules/user'
import { useNavigate } from 'react-router-dom'

/**
 * @method queryLogin()
 * @returns
 */
export const useQueryLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(signIn, {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 200) {
        setStoredAuth(data.data)
        queryClient.refetchQueries()
        notification.success({ message: data.message || 'Login Success!' })
      }
    },
    onError: (error: ResApiErr) => {
      clearStoredAuth()
      queryClient.setQueryData(GET_PROFILE_2, null)
      notification.error({ message: error.message || 'Login failure!' })
    },
  })
}

export const useQueryLoginByTokenWp = () => {
  const queryClient = useQueryClient()
  return useMutation(signInByTokenWp, {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 200) {
        setStoredAuth(data?.data)
        queryClient.refetchQueries()
        notification.success({ message: 'Đăng nhập thành công!' })
      }
    },
    onError: (error: ResApiErr) => {
      clearStoredAuth()
      queryClient.setQueryData(GET_PROFILE_2, null)
      notification.error({ message: error.message || 'Đăng nhập thất bại!' })
    },
  })
}

/**
 * @method queryLogOut()
 * @return
 */
export const useQueryLogOut = () => {
  const queryClient = useQueryClient()
  const accessToken = checkAuth()
  const navigator = useNavigate()
  return useMutation(() => signOut(accessToken), {
    onSuccess: (data: ResApi) => {
      logger.debug('SignOut data:', data)
      // Todo
    },
    onError: (error: ResApiErr) => {
      logger.debug('SignOut error:', error)
      // Todo
    },
    onSettled: (data, error, variables, context) => {
      logger.debug('onSettled', data, error, variables, context)
      queryClient.setQueryData(GET_PROFILE_2, null)
      clearStoredAuth()
      navigator('/kiem-tra/khoi-dong')
    },
  })
}

/**
 * @method queryProfile
 * @returns
 */
export const useQueryProfile = (accessToken: string) =>
  useQuery<ResApi<TProfile>>([USER_PROFILE], () => getProfile(accessToken), {
    enabled: !!accessToken,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: 2,
  })

// Function
/**
 * @method refreshTokenFn
 */
export const refreshTokenFn: () => void = async () => {
  const accessToken = checkAuth()
  const signature: Auth | null = getStoredAuth()
  if (signature) {
    const result: ResApi = await refreshToken(accessToken, {
      refreshToken: signature?.refreshToken || '',
    })
    if (result.statusCode === 200)
      setStoredAuth({
        ...signature,
        accessToken: result.data.accessToken,
        expiredAt: result.data.expiredAt,
      })
    return result
  }
  // TODO ...
  return false
}
