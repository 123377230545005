import { TSubmitAnswer } from '@src/modules/result'

import { request } from './config'

export const getListResult = (params: any, token: string) =>
  request({ url: 'result', method: 'GET', params }, { token })
export const getListResultByUser = (params: any, token: string) =>
  request({ url: 'result/user', method: 'GET', params }, { token })
export const getDetailResult = (id: string, token: string) => request({ url: `result/${id}`, method: 'GET' }, { token })
export const submitAnswer = (id: string, data: TSubmitAnswer, token: string) =>
  request({ url: `result/submit-answer/${id}`, data, method: 'PATCH' }, { token })
export const submitExam = (id: string, token: string) =>
  request({ url: `result/submit-exam/${id}`, method: 'PATCH' }, { token })
export const getDetailResultByTokenWp = (id: string, token: string) =>
  request({ url: `result/user-wp/${id}`, params: { token }, method: 'GET' })
