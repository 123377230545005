import { Col, Layout, Row, Space, Typography } from 'antd'
import logo from '@assets/images/logo-ft.png'
import bct from '@assets/images/bct.png'
import { FacebookFilled, InstagramFilled, MailFilled, PhoneFilled, YoutubeFilled } from '@ant-design/icons'
import { MAIN_LINK } from '@src/configs/unlockyourcareLink'

const { Paragraph } = Typography

const { Footer: FooterAnt } = Layout
function Footer() {
  return (
    <FooterAnt className="footer-layout">
      <div className="tw-container ft-context">
        <div className="tw-wrapper">
          <Row gutter={[80, 40]}>
            <Col lg={8} sm={24} xs={24}>
              <Row gutter={[0, 32]}>
                <Col span={24}>
                  <img height="83px" width="236px" src={logo} alt="logo-footer" />
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 25]}>
                    <Col span={24}>
                      <span className="ft-label">Kết nối với chúng tôi</span>
                    </Col>
                    <Col span={24}>
                      <Space size={24}>
                        <a href={MAIN_LINK.FACEBOOK} target="_blank" rel="noreferrer">
                          <FacebookFilled style={{ color: '#ffffff', fontSize: 20 }} />
                        </a>
                        <a href={MAIN_LINK.YOUTUBE} target="_blank" rel="noreferrer">
                          <YoutubeFilled style={{ color: '#ffffff', fontSize: 20 }} />
                        </a>
                        <a href={MAIN_LINK.INSTAGRAM} target="_blank" rel="noreferrer">
                          <InstagramFilled style={{ color: '#ffffff', fontSize: 20 }} />
                        </a>
                        <a href={`mailto:${MAIN_LINK.MAIL}`} target="_blank" rel="noreferrer">
                          <MailFilled style={{ color: '#ffffff', fontSize: 20 }} />
                        </a>
                        <a href={`tell:${MAIN_LINK.PHONE_NUMBER}`} target="_blank" rel="noreferrer">
                          <PhoneFilled style={{ color: '#ffffff', fontSize: 20 }} />
                        </a>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Paragraph style={{ color: '#BFBFBF' }}>PAC GROUP - UNLOCK YOUR CAREER</Paragraph>
                  <Paragraph style={{ color: '#BFBFBF' }}>
                    Số giấy chứng nhận đăng ký kinh doanh: 0103103663 <br />
                    Đăng ký lần đầu ngày: 18/12/2008 <br />
                    Nơi cấp: Phòng Đăng ký kinh doanh - Sở kế hoạch đầu tư Hà Nội <br />
                    Địa chỉ trụ sở chính: P311 Toà nhà The Golden Palm Số 21 Lê Văn Lương, Thanh Xuân, Hà Nội <br />
                    Điện thoại: 024 37764098 - Fax: 024 37765590 <br />
                    Email: info@pacgroup.org <br />
                    Website: www.unlockyourcareer.vn
                  </Paragraph>
                  <Paragraph style={{ color: '#BFBFBF' }} />
                </Col>
                <Col span={24}>
                  <a href="http://online.gov.vn/Home/WebDetails/79501?AspxAutoDetectCookieSupport=1" target="_black">
                    <img height="52px" width="140px" src={bct} alt="logo-bct" />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={9} xs={24}>
              <Row gutter={[0, 30]}>
                <Col span={24}>
                  <span className="ft-label">Về Unlock Your Career</span>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={12}>
                    <a href={MAIN_LINK.ADVISORY_TEAM} rel="noreferrer">
                      Giới thiệu chung
                    </a>
                    <a href={MAIN_LINK.EVALUATION_MODEL}>Mô hình đánh giá</a>
                    <a href={MAIN_LINK.USAGE_POLICY}>Chính sách sử dụng</a>
                    <a href={MAIN_LINK.PRIVACY_POLICY}>Chính sách bato mật thông tin</a>
                    <a href={MAIN_LINK.PAYMENT_POLICY}>Chính sách thanh toán</a>
                    <a href={MAIN_LINK.USER_GUIDE_VIDEO}>Video hướng dẫn sử dụng</a>
                    <a href={MAIN_LINK.FAQ}>Câu hỏi hường gặp</a>
                    <a href={MAIN_LINK.CONTACT}>Liên hệ</a>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col lg={7} sm={9} xs={24}>
              <Row gutter={[0, 30]}>
                <Col span={24}>
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <span className="ft-label">Các giải pháp</span>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" size={12}>
                        <a href={MAIN_LINK.CAREER_SOLUTIONS}>Hướng nghiệp trực tuyến</a>
                        <a href={MAIN_LINK.CAREER_SOLUTIONS}>Hướng nghiệp cùng chuyên gia</a>
                        <a href={MAIN_LINK.COURSES}>Khoá học học thuật và phát triển bản thân</a>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <span className="ft-label">Dự án</span>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" size={12}>
                        <span>Đào tạo cố vấn hướng nghiệp</span>
                        <span>Hợp tác doanh nghiệp và trường học</span>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={3} sm={6} xs={24}>
              <Row gutter={[0, 30]}>
                <Col span={24}>
                  <span className="ft-label">Blogs</span>
                </Col>
                <Col>
                  <Space direction="vertical" size={12}>
                    <a href={MAIN_LINK.NEWS}>Tin tức</a>
                    <a href={MAIN_LINK.EVENT}>Sự kiện</a>
                    <a href={MAIN_LINK.PODCAST}>Podcast & Vlog</a>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="copyright">
        <div className="tw-container">
          <div className="tw-wrapper">
            <span>© Copyright 2020 PAC Group Viet Nam. All rights reserved.</span>
          </div>
        </div>
      </div>
    </FooterAnt>
  )
}

export default Footer
