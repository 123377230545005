import {
  CaretDownOutlined,
  FacebookFilled,
  InstagramFilled,
  LogoutOutlined,
  MailFilled,
  MenuFoldOutlined,
  PhoneFilled,
  UserOutlined,
  YoutubeFilled,
} from '@ant-design/icons'
import { Avatar, Button, Col, Dropdown, Layout, Row, Space, Spin, Typography } from 'antd'
import logo from '@assets/images/logo.png'
import { useEffect, useMemo, useState } from 'react'
import { TProfile } from '@src/modules/user'
import { checkAuth } from '@src/libs/localStorage'
import { useGetProfile } from '@src/hooks/useGetProfile'
import { MAIN_LINK } from '@src/configs/unlockyourcareLink'
import { useQueryLogOut } from '@src/queries/hooks'

const { Text } = Typography

const { Header: HeaderAnt } = Layout
function Header() {
  const [showMenuMobile, setShowMenuMobile] = useState(false)

  const [token, setToken] = useState<string>('')

  useEffect(() => {
    setToken(checkAuth())
    window.addEventListener('storage', () => {
      const accessToken = checkAuth()
      setToken(accessToken)
    })
  }, [])

  const { data, isLoading, isFetching } = useGetProfile(token)
  const profile: TProfile | undefined = useMemo(() => data?.data, [data])

  const { mutate: mutateLogout } = useQueryLogOut()

  const handleLogout = () => {
    mutateLogout()
  }
  return (
    <HeaderAnt className="header-layout">
      <Row className="nav-top">
        <div className="tw-container">
          <div className="tw-wrapper">
            <Row align="middle" justify="space-between" className="nav-wrapper-top">
              <Col>
                <Row align="middle">
                  <Col lg={0}>
                    <Button onClick={() => setShowMenuMobile((prev) => !prev)} className="hamburger">
                      <MenuFoldOutlined />
                    </Button>
                  </Col>
                  <Col>
                    <Space size={43}>
                      <Space size={10} align="baseline" className="social-item">
                        <MailFilled style={{ color: '#FFF200', fontSize: 18 }} />
                        <span>{MAIN_LINK.MAIL}</span>
                      </Space>
                      <Space size={10} align="baseline" className="social-item">
                        <PhoneFilled style={{ color: '#FFF200', fontSize: 18 }} />
                        <span>{MAIN_LINK.PHONE_NUMBER}</span>
                      </Space>
                      <Space size={18} align="baseline" className="social-item">
                        <a href={MAIN_LINK.FACEBOOK} target="_blank" rel="noreferrer">
                          <FacebookFilled style={{ color: '#FFF200', fontSize: 18 }} />
                        </a>
                        <a href={MAIN_LINK.YOUTUBE} target="_blank" rel="noreferrer">
                          <YoutubeFilled style={{ color: '#FFF200', fontSize: 18 }} />
                        </a>
                        <a href={MAIN_LINK.INSTAGRAM} target="_blank" rel="noreferrer">
                          <InstagramFilled style={{ color: '#FFF200', fontSize: 18 }} />
                        </a>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col>
                {isLoading || isFetching ? (
                  <Spin />
                ) : (
                  <Dropdown
                    overlay={
                      <Row>
                        <Button type="link" onClick={handleLogout}>
                          <Text>
                            <Space size={10} align="center">
                              <LogoutOutlined /> Đăng xuất
                            </Space>
                          </Text>
                        </Button>
                      </Row>
                    }
                    placement="bottomLeft"
                    arrow
                  >
                    <a href={MAIN_LINK.PROFILE}>
                      <Space size={10} align="center">
                        {profile && (
                          <>
                            <Avatar size={24} icon={<UserOutlined />} />
                            <span style={{ color: '#ffffff' }}>{profile?.name}</span>
                          </>
                        )}
                      </Space>
                    </a>
                  </Dropdown>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row className={`nav-bottom ${showMenuMobile && 'is-active'}`}>
        <div className="tw-container">
          <div className="tw-wrapper">
            <Row align="middle" justify="space-between">
              <Col className="logo-web">
                <a href={MAIN_LINK.HOME}>
                  <img height="57px" width="162px" src={logo} alt="Unlock Your Career" />
                </a>
              </Col>
              <Col className="menu-container">
                <Row gutter={[100, 30]} className="menu">
                  <Col className="menu-item">
                    <Dropdown
                      overlay={
                        <Row>
                          <Col span={24}>
                            <a href={MAIN_LINK.MISSION_VISION}>
                              <Button type="link">
                                <Text>Sứ mệnh và tầm nhìn</Text>
                              </Button>
                            </a>
                          </Col>
                          <Col span={24}>
                            <a href={MAIN_LINK.EVALUATION_MODEL}>
                              <Button type="link">
                                <Text>Mô hình đánh giá</Text>
                              </Button>
                            </a>
                          </Col>
                          <Col span={24}>
                            <a href={MAIN_LINK.ADVISORY_TEAM}>
                              <Button type="link">
                                <Text>Đội ngũ cố vấn</Text>
                              </Button>
                            </a>
                          </Col>
                        </Row>
                      }
                      placement="bottomLeft"
                      arrow
                    >
                      <Space align="center" direction="horizontal" className="menu-item-label">
                        <span>Về Unlock Your Career</span>
                        <CaretDownOutlined />
                      </Space>
                    </Dropdown>
                  </Col>
                  <Col className="menu-item">
                    <Dropdown
                      overlay={
                        <Row>
                          <Col span={24}>
                            <a href={MAIN_LINK.CAREER_SOLUTIONS}>
                              <Button type="link">
                                <Text>Giải pháp hướng nghiệp</Text>
                              </Button>
                            </a>
                          </Col>
                          <Col span={24}>
                            <a href={MAIN_LINK.COURSES}>
                              <Button type="link">
                                <Text>Khoá học</Text>
                              </Button>
                            </a>
                          </Col>
                        </Row>
                      }
                      placement="bottomLeft"
                      arrow
                    >
                      <Space align="center" direction="horizontal" className="menu-item-label">
                        <span>Giải pháp</span>
                        <CaretDownOutlined />
                      </Space>
                    </Dropdown>
                  </Col>
                  <Col className="menu-item">
                    <Space align="center" direction="horizontal">
                      <a href={MAIN_LINK.DATA_SOURCE} className="menu-item-label">
                        Nguồn dữ liệu
                      </a>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </HeaderAnt>
  )
}

export default Header
