import axios, { AxiosRequestConfig } from 'axios'
import { API_TIMEOUT, BASE_URL } from '@configs/api'
import logger from '@libs/logger'
import { LANGUAGE_DEFAULT } from '@src/configs/common'

const client = axios.create({
  baseURL: BASE_URL || 'https://api-explorer.unlockyourcareer.vn/api/v1',
  timeout: API_TIMEOUT,
  timeoutErrorMessage: '🚧🚧🚧 Server connection time out !',
  headers: {
    Accept: 'application/json',
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    responseEncoding: 'utf8',
    responseType: 'json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Access-Control-Allow-Origin': '*',
    'X-Application': 'web app',
    'X-Version': '1.0.1',
  },
})

export const request = async (options: AxiosRequestConfig, additional?: { lang?: string; token?: string }) => {
  client.defaults.headers.common.Authorization = `Bearer ${additional?.token || undefined}`

  client.defaults.headers.common.lang = additional?.lang || LANGUAGE_DEFAULT

  const onSuccess = (response: any) => {
    logger.debug('Response API:', response?.data)
    return response?.data
  }
  const onError = async (error: any) => {
    logger.debug('Axios Options:', options)
    logger.error('Axios error:', error)
    // optionally catch errors and add additional logging here
    await Promise.reject({
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      statusText: error?.response?.statusText,
      status: error?.response?.status,
      data: error?.response?.data?.data || null,
    })
  }
  return client(options).then(onSuccess).catch(onError)
}
